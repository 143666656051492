import axios from "axios";

export const actionHandler = (payload) => {

  return new Promise((resolve, reject) => {
    payload.baseURL = "https://api.kabco.co.in/api/v1";
    axios(payload)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

