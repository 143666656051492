import { actionHandler } from "../api";

const getLogo = async () => {
  try {
    const response = await actionHandler({
      url: `/open/logo/`,
      method: 'GET'
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

const getBanners = async () => {
    try {
      const response = await actionHandler({
        url: `/open/banners/`,
        method: 'GET'
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getLinks = async () => {
    try {
      const response = await actionHandler({
        url: `/open/important-links/`,
        method: 'GET'
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getVision = async () => {
    try {
      const response = await actionHandler({
        url: `/open/visions/`,
        method: 'GET'
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };
  const getVisionPoints = async () => {
    try {
      const response = await actionHandler({
        url: `/open/vision/points`,
        method: 'GET'
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getMission = async () => {
    try {
      const response = await actionHandler({
        url: `/open/missions/`,
        method: 'GET'
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getMissionPoints = async () => {
    try {
      const response = await actionHandler({
        url: `/open/mission/points/`,
        method: 'GET'
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  
  
  export { getBanners ,getLinks , getMission,getVision,getLogo ,getVisionPoints,getMissionPoints };