const HeaderData = [
  {
    title: "HOME",
    link: "/",
  },
  {
    title: "ABOUT",
    dropdown: [
      {
        title: "About Us",
        link: "/about-us",
      },
      {
        title: "Management",
        link: "/management",
      },
    ],
  },
  {
    title: "INFRASTRUCTURE",
    link: "/infrastructure",
  },
  {
    title: "CAREERS",
    link: "/careers",
  },
  {
    title: "NEWS",
    dropdown: [
      {
        title: "News",
        link: "/news",
      },
      {
        title: "Events",
        link: "/events",
      },

      {
        title: "Gallery",
        link: "/gallery",
      },
    ],
  },
  {
    title: "EOI / TENDERS",
    dropdown: [
      {
        title: "EOI / Tenders",
        link: "/tenders",
      },
    ],
  },
  {
    title: "CONTACT US",
    link: "/contact",
  },
];

export default HeaderData;
