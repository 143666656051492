const FooterData = [
    {
        title: "Quick Links",
        submenu: [
            {
                title: "NAWO-DHAN EOI Application",
                link: "https://nawodhan.kabco.co.in/eoi-registration",
                redirection: true
            },
            {
                title: "NAWO-DHAN RFP for Matchmaking",
                link: "https://rfp.kabco.co.in/signin",
                redirection: true
            },
            {
                title: "Home",
                link: "/",
                redirection: true
            },
            {
                title: "About us",
                link: "/about-us",
                redirection: true
            },
            {
                title: "Our Mission",
                link: "#our-mission",
                redirection: false

            },
            {
                title: "Our Vision",
                link: "#our-vision",
                redirection: false
            },
        ]
    },
    {
        title: "More Links",
        submenu: [
            {
                title: "Contact Us",
                link: "/contact-us",
                redirection: true
            },
            {
                title: "Media",
                link: "/media",
                redirection: true
            },
            {
                title: "Careers",
                link: "/careers",
                redirection: true
            },
            {
                title: "News",
                link: "/news",
                redirection: true
            },
            {
                title: "Gallery",
                link: "/gallery",
                redirection: true
            },
            {
                title: "Tenders",
                link: "/tenders",
                redirection: true
            },
            {
                title: "Events",
                link: "/events",
                redirection: true
            },
        ]
    },
]

export default FooterData