import React, { useEffect, useState } from 'react'
import Header, { HeaderNav, Menu } from '../Components/Header/Header'
import { Col, Container, Navbar, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { getLogo } from '../Store/home'
import { useQuery } from '@tanstack/react-query'
import MainFooter from '../Components/Footers/MainFooter'


const GalleryDetailed = (props) => {
  const { GalleryId } = useParams()
  const [gallery, setGallery] = useState([])
  const First = ['/assets/img/WhatsApp Image 2025-02-01 at 14.08.44_0c6f0532.jpg',
    '/assets/img/WhatsApp Image 2025-02-01 at 14.08.45_f0535a4c.jpg',
    '/assets/img/WhatsApp Image 2025-02-01 at 14.08.45_8a1320d2.jpg',
    '/assets/img/WhatsApp Image 2025-02-01 at 14.08.46_5ea90c85.jpg',
    '/assets/img/WhatsApp Image 2025-02-01 at 14.08.47_e0a3c339.jpg',
    '/assets/img/WhatsApp Image 2025-02-01 at 14.08.47_e718ec8c.jpg'
  ]
  const Second = ['/assets/img/WhatsApp Image 2025-01-31 at 10.15.49_75c8d805.jpg',
    '/assets/img/WhatsApp Image 2025-01-31 at 10.15.50_9423f970.jpg',
    '/assets/img/WhatsApp Image 2025-01-31 at 10.15.51_d4298f52.jpg',
    '/assets/img/WhatsApp Image 2025-01-31 at 10.15.51_6cc1076a.jpg',
  ]

  console.log("GalleryId>>>", GalleryId)

  useEffect(() => {
    if (GalleryId === '1') {
      setGallery(First)
    } else {
      setGallery(Second)
    }
  }, [])


  const { data: logo } = useQuery({
    queryKey: ['logo'],
    queryFn: () => getLogo(),
  })
  return (
    <>
      <Header className="header-with-topbar  h-[100px]" topSpace={{ desktop: true }} type="always-fixed">
        <HeaderNav theme="light" bg="white" menu="light" className="px-[35px] py-[0px] md:px-[15px] sm:px-0" containerClass="md:px-0">
          <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <div className="inline-block p-0 m-0">
                <img className='w-[120px]' loading="lazy" src={logo?.data?.image} data-rjs="/KABCO.png" alt="logo" />
              </div>
            </Link>
          </Col>
          <Navbar.Toggle className="order-last md:mx-[15px]">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="col-auto justify-end p-0">
            <Menu {...props} />
          </Navbar.Collapse>
        </HeaderNav>
      </Header>
      <section className="bg-lightgray py-[40px] sm:py-[30px]">
        <Container>
          <Row className="xs:text-center">
            <Col xl={8} lg={6} className="flex md:justify-center md:mb-[15px] sm:block sm:text-center sm:mb-[9px]">
              <h1 className="text-lg leading-[28px] text-darkgray font-medium mb-0 font-serif inline-block">Photos Gallery Detailed</h1>
              <span className="font-serif text-md leading-[28px] relative pl-[25px] ml-[30px] block sm:p-0 before:absolute before:bg-darkgray before:content-[''] before:h-[12px] before:top-[8px] before:w-[2px] before:left-0 sm:before:hidden sm:ml-0">KABCO</span>
            </Col>
            <Col xl={4} lg={6} className="breadcrumb justify-end px-[15px] text-sm font-serif m-0 md:justify-center">
              <ul>
                <li className="!leading-[30px]"><Link aria-label="link for" to="/">Home</Link></li>
                <li className="!leading-[30px]">Photos Gallery Detailed</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className=" px-[11%] pb-[130px] bg-lightgray xl:px-[2%] lg:pb-[90px] md:px-0 md:pb-[75px] sm:pb-[50px]">
        <Container>
          <div class="mx-auto px-5 py-2 lg:px-32 lg:pt-12">
            <div class="grid grid-cols-3 gap-4 sm:grid-cols-1 lg:grid-cols-2">
              {gallery.map(ele =>
                <div class="p-1 md:p-2">
                  <img
                    alt="gallery"
                    class="block h-full w-full rounded-lg object-cover object-center"
                    src={ele} />
                </div>
              )}
            </div>
          </div>

        </Container>
      </section>
      <MainFooter logo={logo?.data?.image} />

    </>
  )
}

export default GalleryDetailed